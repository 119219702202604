.settings-button {
  padding: 0;
  width: 95%;
  margin: 0;
  margin: 0 auto;
  border: none;
  --lightness: 45%;
  font-size: larger;
  background-color: hsl(var(--hue), var(--saturation), var(--lightness));
  border-bottom: 3px solid var(--line-color);
  color: inherit;
}

.settings-button:active {
  --lightness: 30%;
  background-color: hsl(var(--hue), var(--saturation), var(--lightness));
}

.settings:has(.settings-button:active) .settings-wrapper {
  --lightness: 30%;
  background-color: hsl(var(--hue), var(--saturation), var(--lightness));
}

.settings:has(.settings-wrapper:active) .settings-button {
  --lightness: 30%;
  background-color: hsl(var(--hue), var(--saturation), var(--lightness));
}

.settings:has(.settings-wrapper) .settings-button {
  border-bottom: none;
}

.settings:has(.settings-wrapper) .settings-button .right {
  transform: rotateX(180deg);
  margin-top: 0.75em;
}

.left {
  padding-left: 1em;
  font-size: larger;
  text-align: left;
  float: inline-start;
}

.right {
  text-align: right;
  padding-right: 1em;
  margin-top: 1em;
}

.right img {
  height: 1.4em;
  width: 1.6em;
}

.settings-wrapper {
  display: inline-block;
  width: 95%;
  padding: 0;
  padding-bottom: 1em;
  margin: 0 auto;
  --lightness: 45%;
  background-color: hsl(var(--hue), var(--saturation), var(--lightness));
  border-bottom: 3px solid var(--line-color);
}

.settings-wrapper:active {
  --lightness: 30%;
  background-color: hsl(var(--hue), var(--saturation), var(--lightness));
}

.settings-wrapper p {
  padding: 0;
  margin: 0;
  margin-top: 1em;
  margin-bottom: 1em;
  font-size: larger;
}

.settings-wrapper button {
  margin: 10px;
  border: none;
  padding: 0.5em 1em;
  --lightness: 80%;
  background-color: hsl(var(--hue), var(--saturation), var(--lightness));
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  font-size: larger;
  color: inherit;
}

.settings-wrapper button:hover {
  --lightness: 70%;
  background-color: hsl(var(--hue), var(--saturation), var(--lightness));
}