/* navigation bar */
.footer ul {
    list-style-type: none;
    margin: 0;
    padding-right: 1em;
    overflow: hidden;
    position: fixed;
    bottom: 0px;
    width: 100%;
    background-color: hsl(var(--hue), var(--saturation), var(--lightness));
    border-top: 3px solid var(--line-color);
    right: 0;
  }
  
  /* navigation bar buttons */
  .footer li {
    float: right;
    font-size: inherit;
  }

  .footer img {
    height: 2.2em;
    width: 2.2em;
  }
  
  /* specific navigation bar button */
  .footer li a {
    display: block;
    color: black;
    text-align: center;
    padding: 0.2em 0.4em;
    text-decoration: none;
  }
  
  /* hover action for navigation bar buttons */
  .footer li a:hover {
    --lightness: 45%;
    background-color: hsl(var(--hue), var(--saturation), var(--lightness));
  }