/* navigation bar */
.nav ul {
    list-style-type: none;
    margin: 0;
    padding-left: 1em;
    overflow: hidden;
    position: fixed;
    left: 0;
    top: 0px;
    width: 100%;
    background-color: hsl(var(--hue), var(--saturation), var(--lightness));
    border-bottom: 3px solid var(--line-color);
  }
  
  /* navigation bar buttons */
  .nav li {
    float: left;
  }
  
  /* specific navigation bar button */
  .nav li a {
    display: block;
    text-align: center;
    padding: 0.4em 0.4em;
    text-decoration: none;
    font-size: 150%;
    color: inherit;
  }
  
  /* hover action for navigation bar buttons */
  .nav li a:hover {
    --lightness: 45%;
    background-color: hsl(var(--hue), var(--saturation), var(--lightness));
  }