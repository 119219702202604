.container-out {
  font-family: "Fira Sans", sans-serif;
  padding: 15px 0;
  max-width: 400px;
  margin: 0 auto;
}

.container-in {
  padding: 30px 0;
  width: 95%;
  margin: 0 auto;
  border-radius: 5px;
  border: 2px solid black;
  --lightness: 45%;
  background-color: hsl(var(--hue), var(--saturation), var(--lightness));
}

.contact-form {
  width: 85%;
  margin: 0 auto;
}

.error {
  margin: 0;
  margin: 5px 0;
  color: black;
  font-style: oblique;
}

.hidden {
  display: none;
  visibility: hidden;
}

.contact-form p {
  margin: 0;
  margin: 5px 0;
}

.contact-form h1 {
  margin: 0;
  margin-bottom: 20px;
}

.contact-form input {
  border: none;
  width: 100%;
  margin-bottom: 15px;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 3px;
}

.contact-form textarea {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 3px;
  min-height: 120px;
  margin-bottom: 15px;
  resize: vertical;
}

.contact-form button {
  margin: 10px;
  border: none;
  padding: 0.5em 1em;
  --lightness: 80%;
  background-color: hsl(var(--hue), var(--saturation), var(--lightness));
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  font-size: larger;
  color: inherit;
}

.contact-form button:hover {
  opacity: 0.7;
}

.alert {
  margin-bottom: 1em;
  padding: 10px;
  --lightness: 45%;
  background-color: hsl(var(--hue), var(--saturation), var(--lightness));
  font-family: 'Mukta Mahee', sans-serif;
  font-size: larger;
  text-align: center;
  border-radius: 2.5px;
  display: none;
  font-weight: bold;
}

.settings-out {
  padding-bottom: 2em;
}